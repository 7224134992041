import React from 'react';
import { Layout } from '../../components1/layout';

import MyPreviousOrders from '../../containers/user/MyPreviousOrders';
import PrivateRoute from '../../components1/PrivateRoute';

const Orders = PrivateRoute(MyPreviousOrders);

const OrdersPage = () => {
    return (
        <Layout isUserPage>
            {
                () => (
                    <Orders />
                )
            }
        </Layout>
    );
};

export default OrdersPage;
