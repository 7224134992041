import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import dayjs from 'dayjs';
import Media from 'react-media';

import { getOrders } from '../../services';
import { weekIdToDate, weekIdToShortDate } from '../../services/dates';
import FlexDiv from '../../components1/FlexDiv';
import { Title, Text as TextBeforeStyle, Bold} from '../../components1/Texts';
import { MapMarkerIcon, TimeIcon2, RightHandIcon, DownHandIcon } from '../../components1/Icons';
import { deliveryData, recipesPrices, subscriptionPrice } from '../../Constants';

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: minmax(2.25rem, auto);
    grid-column-gap: 30px;
    grid-row-gap: 5px;
    padding: 0 30px;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 699px) {
        grid-template-columns: 1fr 1fr 1fr 1fr !important;
        grid-column-gap: 5px !important;
        padding: 0 5px !important;
    }
`;

const Header = styled(Title)`
    text-align: center;
    font-size: 1.250rem;
    @media only screen and (max-width: 599px) {
        font-size: 0.875rem !important;
    }
`;

const Text = styled(TextBeforeStyle)`
    text-align: center;
    ${props => props.red && `color: ${props.theme.color.rougeFort};`}
    @media only screen and (max-width: 599px) {
        font-size: 0.75rem !important;
    }
`;

const DetailView = styled(FlexDiv)`
    background-color: ${props => props.theme.color.rougeTresFaible};
    margin: 0 100px;
    padding: 30px;
    grid-column: 1 / 7;
    flex-direction: column;
    ${Text} {
        text-align: left;
    }
    @media only screen and (max-width: 599px) {
        margin: 0 !important;
        padding: 0 !important;
        grid-column: 1 / 5 !important;
        margin: 0 30px !important;
        padding: 10px !important;
    }
`;

const OrderDetailView = React.memo(({ order }) => {
    console.log(order);
    return (
        <DetailView>
            <Text style={{paddingBottom: 5}}>{`Contenu de mon CABADICI du ${weekIdToDate(order.weekId)} :`}</Text>
            <FlexDiv style={{justifyContent: 'space-between'}}>
                <Title
                    style={{fontSize: '1.5rem', padding: '5px 0'}}
                    css={`
                        @media only screen and (max-width: 599px) {
                            font-size: 1.125rem !important;
                        }
                    `}
                >
                    {`${order.recipes.recipesNumber} RECETTES:`}
                </Title>
                <NumberFormat
                    value={order.recipes.price}
                    displayType="text"
                    renderText={value => <Text style={{padding: '5px 0', alignSelf: 'center'}}>{value}</Text>}
                    thousandSeparator
                    suffix="€"
                    decimalScale={2}
                    fixedDecimalScale
                />
            </FlexDiv>
            {
                order.recipes.recipesSelection.map((recipe, id) => (
                    <Text key={id.toString()} style={{padding: '0 10px'}}>
                        {`- ${recipe.name}`}
                    </Text>))
            }
            <Title
                style={{fontSize: '1.5rem', padding: '10px 0 5px 0'}}
                css={`
                    @media only screen and (max-width: 599px) {
                        font-size: 1.125rem !important;
                    }
                `}
            >
                Vos compléments:
            </Title>
            {
                order.grocery.itemsSelection.map(({name, unitPrice, itemNumber}, id) => (
                    <FlexDiv style={{justifyContent: 'space-between', paddingLeft: 10}} key={id.toString()}>
                        <Text>
                            {`- ${name}`}
                            {
                                itemNumber > 1 && <Bold>{` x${itemNumber}`}</Bold>
                            }
                        </Text>
                        <NumberFormat
                            value={unitPrice*itemNumber}
                            displayType="text"
                            renderText={value => <Text>{value}</Text>}
                            thousandSeparator
                            suffix="€"
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    </FlexDiv>
                ))
            }
            {
                !order.subscription.isSubscription && (
                    <FlexDiv style={{justifyContent: 'space-between'}}>
                        <Title
                            style={{fontSize: '1.5rem', padding: '10px 0 5px 0'}}
                            css={`
                                @media only screen and (max-width: 599px) {
                                    font-size: 1.125rem !important;
                                }
                            `}
                        >
                            {`Sans abonnement:`}
                        </Title>
                        <NumberFormat
                            value={order.subscription.price}
                            displayType="text"
                            renderText={value => <Text style={{alignSelf: 'center'}}>+{value}</Text>}
                            thousandSeparator
                            suffix="€"
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    </FlexDiv>
                )
            }
            <Title
                style={{fontSize: '1.5rem', padding: '10px 0 5px 0'}}
                css={`
                    @media only screen and (max-width: 599px) {
                        font-size: 1.125rem !important;
                    }
                `}
            >
                {`Livraison:`}
            </Title>
            <FlexDiv style={{justifyContent: 'space-between'}}>
                <Text>
                    {`- ${order.delivery.deliveryChoiceData.text}:`}
                </Text>
                {
                    // eslint-disable-next-line no-restricted-globals
                    isNaN(order.delivery.deliveryChoiceData.price) ?
                        (
                            <Text>
                                {order.delivery.deliveryChoiceData.price}
                            </Text>
                        ) : (
                            <NumberFormat
                                value={order.delivery.deliveryChoiceData.price}
                                displayType="text"
                                renderText={value => <Text>{value}</Text>}
                                thousandSeparator
                                suffix="€"
                                decimalScale={2}
                                fixedDecimalScale
                            />
                        )
                }
            </FlexDiv>
            {
                order.delivery.deliveryChoice === 0 && (
                    <Text><MapMarkerIcon /><span style={{paddingLeft: 5}} >{`${order.delivery.partnerDeliveryAddress.name} (${order.delivery.partnerDeliveryAddress.location})`}</span></Text>
                )
            }
            {
                order.delivery.deliveryChoice === 2 && (
                    <Text><MapMarkerIcon /><span style={{paddingLeft: 5}} >{`${order.delivery.privateDeliveryAddress.deliveryAddress}, ${order.delivery.privateDeliveryAddress.deliveryPostalCode} ${order.delivery.privateDeliveryAddress.deliveryCity}`}</span></Text>
                )
            }
            <Text><TimeIcon2 /><span style={{paddingLeft: 5}} >{order.delivery.deliveryHoursChoiceData.text}</span></Text>
            <Text red style={{paddingTop: 20}}>{'Pour recevoir ma facture, j’envoie un mail à '}<a href="mailto:contact@cabadici.fr" css={`color: ${props => props.theme.color.rougeFort};`}>contact@cabadici.fr</a></Text>
        </DetailView>
    );
});


const MobileDetailOrder = ({ order }) => {
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <Text
                css={`
                    @media only screen and (max-width: 599px) {
                        font-size: 0.75rem !important;
                        white-space: nowrap;
                    }
                `}
            >
                {dayjs(order.date.toDate()).format('DD/MM/YY')}
            </Text>
            <FlexDiv style={{flexDirection: 'column', alignItems: 'flex-start', alignSelf: 'center'}}>
                <Text
                    css={`
                        @media only screen and (max-width: 599px) {
                            font-size: 0.75rem !important;
                            white-space: nowrap;
                        }
                    `}
                >
                    {`Livraison du ${weekIdToShortDate(order.weekId)}`}
                </Text>
                <Text
                    css={`
                        @media only screen and (max-width: 599px) {
                            font-size: 0.625rem !important;
                            white-space: nowrap;
                        }
                    `}
                >
                    {'Etat: '}
                    <span
                        css={`
                            @media only screen and (max-width: 599px) {
                                font-size: 0.625rem !important;
                                white-space: nowrap;
                                color: ${props => props.theme.color.vertFort}
                            }
                        `}
                    >
                        {order.state}
                    </span>
                </Text>
            </FlexDiv>
            <NumberFormat
                value={order.price.totalOrder}
                displayType="text"
                suffix="€"
                renderText={value => <Text>{value}</Text>}
                decimalScale={2}
                fixedDecimalScale
                decimalSeparator=","
            />
            <div
                role="button"
                onClick={()=> {setOpen(!isOpen);}}
                style={{
                    justifySelf: 'center',
                }}
            >
                {
                    !isOpen ? (
                        <RightHandIcon
                            css={`
                                &:hover{
                                    cursor: pointer;
                                    color: grey;
                                }
                            `}
                        />
                    ) : (
                        <DownHandIcon
                            css={`
                                &:hover{
                                    cursor: pointer;
                                    color: grey;
                                }
                            `}
                        />
                    )
                }
            </div>
            {
                isOpen && <OrderDetailView order={order} />
            }
        </>
    )
};

const DetailOrder = ({ order }) => {
    const [showDetail, setShowDetail] = useState(false);
    const toggleShowDetail = useCallback(() => setShowDetail(!showDetail), [showDetail]);

    return (
        <>
            <Text>
                {dayjs(order.date.toDate()).format('DD/MM/YY')}
            </Text>
            <Text>
                {order.orderId}
            </Text>
            <Text>
                {weekIdToDate(order.weekId)}
            </Text>
            <Text>
                {order.state}
            </Text>
            <NumberFormat
                value={order.price.totalOrder}
                displayType="text"
                suffix="€"
                renderText={value => <Text>{value}</Text>}
                decimalScale={2}
                fixedDecimalScale
                decimalSeparator=","
            />
            <Text
                style={{
                    fontWeight: 600,
                    textDecoration: 'underline',
                }}
                css={`
                    :hover{
                        cursor: pointer;
                    }
                `}
                onClick={toggleShowDetail}
            >
                {
                    showDetail ? 'Masquer' : 'Afficher'
                }
            </Text>
            {
                showDetail && <OrderDetailView order={order} />
            }
        </>
    );
};


const MyPreviousOrder = () => {
    const [orders, setData] = useState([]);

    const fetchData = async () => {
        const ordersData = await getOrders().then(qs => qs.docs.map(doc => doc.data()));
        setData(ordersData.slice(0, 5));
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <FlexDiv
            style={{
                flexDirection: 'column',
                padding: 20
            }}
            css={`
                @media only screen and (max-width: 599px) {
                    padding: 20px 5px !important;
                }
            `}
        >
            <Title
                style={{
                    textAlign: 'center',
                    padding: 30,
                }}
                css={`
                    @media only screen and (max-width: 699px) {
                        padding: 20px !important;
                        font-size: 1.5rem !important;
                    }
                `}
            >
                Mes dernières commandes
            </Title>
            <Grid>
                <Media
                    query="(max-width: 699px)"
                    render={() => (
                        <>
                            <Header>Date</Header>
                            <Header>Etat</Header>
                            <Header>Montant</Header>
                            <Header>Détail</Header>
                            {
                                orders.map((order, idx) => (
                                    <MobileDetailOrder order={order} key={idx.toString()}/>
                                ))
                            }
                        </>
                    )}
                />
                <Media
                    query="(min-width: 699px)"
                    render={() => (
                        <>
                            <Header>Date</Header>
                            <Header
                                css={`
                                    @media only screen and (min-width: 599px) {
                                        display: none !important;
                                    }
                                `}
                            >Réf</Header>
                            <Header
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        display: none !important;
                                    }
                                `}
                            >Référence</Header>
                            <Header>Livraison</Header>
                            <Header>Etat</Header>
                            <Header>Montant</Header>
                            <Header>Détail</Header>
                            {
                                orders.map((order, idx) => (
                                    <DetailOrder order={order} key={idx.toString()}/>
                                ))
                            }
                        </>
                    )}
                />
            </Grid>
        </FlexDiv>
    );
};

export default MyPreviousOrder;
