import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import { compose } from 'redux';
// import { isLoggedIn } from '../utils/auth';

const PrivateRoute_ = ({ component: Component, location, ...rest }) => {
    if (!isLoggedIn() && location.pathname !== `/app/login`) {
        // If we’re not logged in, redirect to the home page.
        navigate(`/app/login`)
        return null
    }

    return <Component {...rest} />
}

PrivateRoute_.propTypes = {
    component: PropTypes.any.isRequired,
}

const mapStateToProps = ({isLogged, authUserData}) => ({isLogged, authUserData});

const PrivateRoute = (Component) => {
    return (tutu) => {
        const {isLogged, authUserData, ...props} = tutu;
        if (typeof window !== 'undefined' && !isLogged) {
            navigate('/', {state: {doOpenConnectForm: true}, replace: true});
            return null
        }
        /*
        if ((typeof window !== 'undefined' && !authUserData) || (typeof window !== 'undefined' && !authUserData.isCustomer)) {
            navigate('/', {state: {doOpenConnectForm: false}, replace: true});
            return null
        }
        */
        return <Component {...props} />;
    }
}

export default compose(connect(mapStateToProps, null), PrivateRoute);
